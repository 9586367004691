import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';  // Import CSS cho Autoplay
import { Autoplay } from 'swiper/modules';
import styles from "./Tourlist.module.css";

const Tourlist = ({ db }) => {
    const [tours, setTours] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "tourlist"));
                const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setTours(data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching data from Firestore:", error);
            }
        };

        fetchData();
    }, [db]);

    const handleTourClick = (id) => {
        navigate(`/tourlist/${id}`); // Navigate to TourContent with tourId
    };
    const formatPrice = (price, currency) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ' + currency;
    };

    return (
        <div className={styles.container}>
            <h1 className={`text-center mb-5 ${styles.title}`}>TOUR</h1>
            {isLoading ? (
                <p className="text-center">Loading...</p>
            ) : (
                <Swiper
                    slidesPerView={1}
                    breakpoints={{
                        768: { slidesPerView: 3 },
                    }}
                    spaceBetween={30}
                    autoplay={{ delay: 5000 }}
                    loop={true}

                    modules={[Autoplay]}  // Thêm các modules ở đây
                    className={styles.swiperContainer}
                >
                    {tours.map((tour) => (
                        <SwiperSlide key={tour.id} className={styles.swiperSlide}>
                            <div className={styles.tourcard} onClick={() => handleTourClick(tour.id)}>
                                <img
                                    className={styles.tourImage}
                                    src={tour.img}
                                    alt={tour.title}
                                    onError={(e) => { e.target.onerror = null; }}
                                />
                                <div className={styles.cardBody}>
                                    <div className={styles.tourTitle}>
                                        <h1>{tour.title}</h1>
                                        <h3>{tour.route}</h3>
                                        <p>{tour.duration}</p>
                                    </div>
                                    <div className={styles.tourPrice}><p>{formatPrice(tour.price, tour.currency)}</p></div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

            )
            }
        </div >
    );
};

export default Tourlist;
