import React, { useEffect, useState, Suspense, lazy } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'; // Import useSelector và useDispatch
import { checkLoginState } from './components/funtion/AuthSlice'; // Import action checkLoginState
import Header from "./components/header/Header";
import Body from "./components/body/Body";
import Footer from "./components/footer/Footer";
import LoadingPage from "./components/common/Loadingpage";
import Navbar from "./components/header/Navbar";
import LoginModal from "./components/common/LoginModal";
import CustomBackground from './components/common/CustomBackground';
import { db } from "./components/funtion/Firebaseconfig";
import 'bootstrap/dist/css/bootstrap.min.css';

const BlogContent = lazy(() => import("./components/body/BlogContent"));
// const Blog = lazy(() => import("./components/body/Blog"));
const BlogPosts = lazy(() => import("./components/body/BlogPosts"));
const Tourlist = lazy(() => import("./components/body/Tourlist"));
const TourContent = lazy(() => import("./components/body/TourContent"));
const AdminDashBoard = lazy(() => import("./components/admin/AdminDashBoard"));
const AboutUs = lazy(() => import("./components/footer/AbouUs"));
function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch(); // Khởi tạo useDispatch

  // Sử dụng useSelector để lấy trạng thái từ Redux store
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  useEffect(() => {
    // Kiểm tra trạng thái đăng nhập khi khởi động ứng dụng
    dispatch(checkLoginState());

    const timer = setTimeout(() => setIsLoading(false), 1300);
    return () => clearTimeout(timer);
  }, [dispatch]);

  const isHomePage = location.pathname === '/'; // Kiểm tra xem có phải trang chủ hay không

  return (
    <>
      {isLoading && <LoadingPage />}
      {!isLoading && (
        <div>
          <CustomBackground />
          <Navbar isAdmin={isAdmin} isLoggedIn={isLoggedIn} /> {/* Truyền các giá trị từ Redux */}

          {isHomePage && <Header />} {/* Hiển thị Header chỉ khi đang ở trang chủ */}

          <Suspense fallback={<LoadingPage />}>
            <Routes>
              <Route path="/" element={<Body db={db} />} />
              <Route path="/tourlist" element={<Tourlist db={db} />} />
              <Route path="/tourlist/:postId" element={<TourContent db={db} isLoggedIn={isLoggedIn} isAdmin={isAdmin} />} />
              <Route path="/blog" element={<BlogPosts db={db} />} />
              <Route path="/blog/:postId" element={<BlogContent db={db} isLoggedIn={isLoggedIn} isAdmin={isAdmin} />} />
              <Route path="/aboutus" element={<AboutUs db={db} isLoggedIn={isLoggedIn} isAdmin={isAdmin} />} />
              <Route path="/login" element={<LoginModal db={db} />} />
              <Route
                path="/admin"
                element={isLoggedIn && isAdmin ? <AdminDashBoard db={db} /> : <Navigate to="/login" />}
              />
            </Routes>
          </Suspense>
          <Footer id="footer" />
        </div>
      )}
    </>
  );
}

export default App;
