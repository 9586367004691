import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import styles from "./Slideshow.module.css";
import { collection, getDocs } from "firebase/firestore";

const SlideShow = ({ db }) => {
  const [slidesData, setSlidesData] = useState([]);
  const slideRefs = useRef([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'slideshow'));
        const data = querySnapshot.docs.map(doc => {
          const docData = doc.data();

          // Kiểm tra sự tồn tại và loại dữ liệu
          if (!docData.image || typeof docData.image !== 'string') {
            console.error(`Image is missing or invalid for document ID: ${doc.id}`);
            return null; // Bỏ qua document nếu không hợp lệ
          }

          return {
            id: docData.id,
            image: docData.image,
            title: docData.title || 'No Title', // Dự phòng nếu title không có
            text: docData.text || 'No Description' // Dự phòng nếu text không có
          };
        }).filter(item => item !== null); // Lọc các item không hợp lệ

        setSlidesData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [db]);

  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    const activeSlideRef = slideRefs.current[activeIndex];

    if (activeSlideRef && activeSlideRef.current) {
      activeSlideRef.current.src = slidesData[activeIndex].image;
    }
  };

  return (
    <div className={styles["slideshow-wrapper"]}>
      <h1>AROUND VIETNAM</h1>
      <Swiper
        modules={[Autoplay]}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop={true}
        spaceBetween={10}
        slidesPerView={1}
        slidesPerGroup={1}
        speed={3000}
        onSlideChange={handleSlideChange}
      >
        {slidesData.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className={styles.slide}>
              <img
                ref={(el) => (slideRefs.current[index] = el)}
                src={slide.image}
                alt={slide.title}
                loading="lazy"
              />
              <div className={styles.caption}>
                <h2 className={styles["caption-title"]}>{slide.title}</h2>
                <p className={styles["caption-text"]}>{slide.text}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SlideShow;
