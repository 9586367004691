import React, { useState } from "react";
import Hero from "./Hero";
import styles from "./Header.module.css";
import { animateScroll as scroll } from "react-scroll";

function Header() {
  const [isScrollingLocked, setIsScrollingLocked] = useState(true);

  const handleScrollToBody = () => {
    setIsScrollingLocked(false);
    scroll.scrollTo(window.innerHeight, {
      duration: 500,
      smooth: true,
    });
  };

  return (
    <header
      className={isScrollingLocked ? styles.containerLocked : styles.container} // Sử dụng class từ CSS module
    >
      <Hero onScrollToBody={handleScrollToBody} />
    </header>
  );
}

export default Header;
