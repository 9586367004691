import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth } from "../funtion/Firebaseconfig";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { login } from '../funtion/AuthSlice';

const LoginModal = ({ db }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const userDoc = await getDoc(doc(db, "user", user.uid));

            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.role === "admin") {
                    dispatch(login({ isAdmin: true })); // Dispatch action login với payload
                    sessionStorage.setItem("isLoggedIn", true);
                    sessionStorage.setItem("isAdmin", true); // Lưu vai trò admin

                    navigate("/admin");
                    console.log("Logged in as admin");
                } else {
                    setError("You do not have admin access");
                }
            } else {
                setError("User data not found");
            }
        } catch (err) {
            setError("Login failed. Please check your email and password.");
            console.error(err);
        }
    };

    return (
        <section className="vh-100 gradient-custom">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card bg-dark text-white" style={{ borderRadius: "1rem" }}>
                            <div className="card-body p-5 text-center">
                                <div className="mb-md-5 mt-md-4 pb-5">
                                    <h2 className="fw-bold mb-2 text-uppercase">Administrator</h2>
                                    <p className="text-white-50 mb-5">Please enter your admin account!</p>

                                    {error && <div className="alert alert-danger">{error}</div>}

                                    <form onSubmit={handleLogin}>
                                        <div className="form-outline form-white mb-4">
                                            <input
                                                type="email"
                                                className="form-control form-control-lg"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                            <label className="form-label">Email</label>
                                        </div>

                                        <div className="form-outline form-white mb-4">
                                            <input
                                                type="password"
                                                className="form-control form-control-lg"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <label className="form-label">Password</label>
                                        </div>

                                        <button className="btn btn-outline-light btn-lg px-5" type="submit">Login</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LoginModal;
