import React from "react";
import styles from "./Info.module.css"; // Import CSS Modules
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap

const Info = () => {
  return (
    <div className={`container ${styles["main-container"]}`}>
      <div className="row">
        {/* Cột 1 */}
        <div className={"col-md-3"}>
          <div className={styles["info-container"]}>
            <div className={styles['section-1']}>
              {/* Nội dung cột 1 sẽ được thêm sau */}
              <h1>LANNA BASSAC TRAVEL</h1>
              <h5>CÔNG TY TNHH THƯƠNG MAI DỊCH VỤ DU LỊCH LANA BASSAC</h5>
              <h6>Thông Tin Chuyển Khoản</h6>
              <p>Chủ Tài Khoản: NGUYEN THI TUYET NHUONG
                <br />
                Số Tài Khoản: 1902 0547 1510 13
                <br />
                Ngân Hàng: TECHCOMBANK
                <br />
                Chi Nhánh: An Hòa</p>
            </div>
          </div>
        </div>
        {/* Cột 2 - Nội dung hiện tại */}
        <div className="col-md-3">
          <div className={styles["info-container"]}>
            <div className={styles["section-2"]}>
              <h1>THÔNG TIN LIÊN HỆ</h1>
              <span className={styles["info-icon"]}>
                contact@lanabassactravel.com
              </span>
              <br />
              <span className={styles["info-icon"]}>
                <a href="tel:+84382903738">+84.38.290.37.38</a>
              </span>
              <br />
              <span className={styles["info-icon"]}>
                <a href="https://www.google.com/maps/search/?api=1&query=205/10+Phan+Van+Han+street,+Binh+Thanh+dist,+HCMC">
                  205/10 Phan Van Han street Binh Thanh dist Ho Chi Minh City
                </a>
              </span>
              <br />
              {/* svg Youtube */}
              <div className={styles.svgbox}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="100"
                  height="100"
                  viewBox="0,0,256,256"
                >
                  <g transform="translate(-1.28,-1.28) scale(1.01,1.01)">
                    <g
                      fill="none"
                      fillRule="nonzero" // Thay đổi từ fill-rule
                      stroke="none"
                      strokeWidth="1" // Thay đổi từ stroke-width
                      strokeLinecap="butt" // Thay đổi từ stroke-linecap
                      strokeLinejoin="miter" // Thay đổi từ stroke-linejoin
                      strokeMiterlimit="10" // Thay đổi từ stroke-miterlimit
                      strokeDasharray=""
                      strokeDashoffset="0"
                      fontFamily="none" // Thay đổi từ font-family
                      fontWeight="none" // Thay đổi từ font-weight
                      fontSize="none" // Thay đổi từ font-size
                      textAnchor="none" // Thay đổi từ text-anchor
                    // style="mix-blend-mode: normal"
                    >
                      <g transform="scale(6.4,6.4)">
                        <path
                          d="M20,38.5c-4.395,-0.001 -10.279,-0.106 -12.53,-0.298c-3.679,-0.324 -3.795,-4.391 -3.917,-8.696c-0.016,-0.533 -0.03,-1.062 -0.053,-1.58c0,-2.354 0.233,-5.083 0.608,-7.1c0.715,-3.026 2.36,-3.078 5.348,-3.172c0.447,-0.014 0.916,-0.029 1.403,-0.056c0.004,0 0.273,-0.004 0.557,-0.01c1.171,-0.027 3.913,-0.089 8.482,-0.089c0,0 0.177,0.001 0.211,0.001c4.602,0 7.313,0.062 8.475,0.088c0.307,0.007 0.5,0.011 0.584,0.011c0.46,0.026 0.929,0.041 1.376,0.055c2.987,0.094 4.633,0.146 5.353,3.195c0.37,1.993 0.604,4.722 0.604,7.098c-0.022,0.496 -0.037,1.025 -0.053,1.558c-0.122,4.305 -0.238,8.372 -3.918,8.696c-2.251,0.193 -8.135,0.298 -12.53,0.299z"
                          fill="#225c95"
                        ></path>
                        <path
                          d="M19.886,18h0.05h0.001l0.076,0.001l0.05,-0.001h0.044c4.56,0 7.296,0.062 8.465,0.089c0.281,0.006 0.469,0.01 0.566,0.011c0.482,0.026 0.946,0.041 1.389,0.055c2.918,0.092 4.25,0.133 4.872,2.764c0.37,1.99 0.6,4.684 0.601,6.986c-0.023,0.521 -0.038,1.053 -0.053,1.589c-0.137,4.787 -0.452,7.945 -3.46,8.21c-2.237,0.191 -8.103,0.295 -12.487,0.296c-4.384,-0.001 -10.251,-0.105 -12.485,-0.296c-3.011,-0.266 -3.326,-3.423 -3.463,-8.21c-0.015,-0.536 -0.03,-1.069 -0.052,-1.546c0,-2.346 0.23,-5.039 0.591,-6.983c0.631,-2.677 1.963,-2.719 4.882,-2.81c0.443,-0.014 0.907,-0.029 1.389,-0.055c0.097,-0.001 0.285,-0.005 0.566,-0.011c1.169,-0.027 3.907,-0.089 8.458,-0.089M20.102,17c-0.036,0 -0.067,0.001 -0.102,0.001c-0.035,0 -0.067,-0.001 -0.102,-0.001c-5.772,0 -8.671,0.1 -9.066,0.1c-3.711,0.204 -6.286,-0.3 -7.214,3.635c-0.413,2.218 -0.618,5.045 -0.618,7.213c0.205,4.698 -0.204,10.343 4.427,10.752c2.364,0.202 8.499,0.299 12.573,0.3c4.074,-0.001 10.209,-0.098 12.573,-0.3c4.631,-0.408 4.222,-6.054 4.427,-10.752c0,-2.168 -0.205,-4.994 -0.617,-7.212c-0.928,-3.936 -3.503,-3.432 -7.214,-3.635c-0.396,-0.001 -3.295,-0.101 -9.067,-0.101z"
                          fill="#163e63"
                        ></path>
                        <path
                          d="M19,35h-2v-1.375c-0.25,0.375 -0.875,1.374 -2.209,1.375c-0.666,0 -1.791,-0.5 -1.791,-2v-9h2v8.625c0,0.23 0.018,0.632 0.75,0.625c0.813,-0.008 1.25,-1 1.25,-1v-8.25h2z"
                          fill="#ffffff"
                        ></path>
                        <path
                          d="M12,23h-2v12h-2v-12h-2v-2h6z"
                          fill="#ffffff"
                        ></path>
                        <path
                          d="M27,26.56c0,-0.863 -0.273,-1.448 -0.622,-1.889c-0.346,-0.441 -0.856,-0.671 -1.505,-0.671c-0.325,0 -0.649,0.09 -0.973,0.255c-0.325,0.162 -0.67,0.445 -0.9,0.805v-4.06h-2v14h2v-1.375c0.386,0.451 0.949,1.386 2,1.375c1.5,0 2,-1.375 2,-2.5zM25,32.023c0,0.552 -0.448,0.977 -1,0.977c-0.552,0 -0.991,-0.212 -1,-0.5v-5.94c0.009,-0.503 0.448,-1 1,-1c0.552,0 1,0.439 1,1.44z"
                          fill="#ffffff"
                        ></path>
                        <path
                          d="M34,30v-3.116c0,-0.998 -0.5,-2.884 -3,-2.884c-2.375,0 -2.997,1.98 -2.997,2.884v4.708c0,1.023 0.278,1.813 0.808,2.392c0.535,0.579 1.276,0.858 2.227,0.858c1.038,0 1.83,-0.258 2.338,-0.815c0.536,-0.533 0.624,-1.342 0.624,-2.435v-0.592h-2v0.5c0,0.603 -0.059,1.066 -0.199,1.231c-0.138,0.184 -0.395,0.265 -0.801,0.269c-0.367,0.004 -0.646,-0.098 -0.784,-0.328c-0.141,-0.209 -0.216,-0.569 -0.216,-1.126v-1.546zM30,27c0.009,-0.537 0.448,-1 1,-1c0.552,0 1,0.385 1,1v1h-2z"
                          fill="#ffffff"
                        ></path>
                        <path
                          d="M15,1l-2,5.6l-2,-5.6h-2l3,8.4v5.6h2v-5.6l3,-8.4z"
                          fill="#163e63"
                        ></path>
                        <path
                          d="M28,4.5v7.75c0,0 -0.437,0.992 -1.25,1c-0.732,0.007 -0.75,-0.395 -0.75,-0.625v-8.125h-2v8.5c0,1.5 1.125,2 1.791,2c1.334,0 1.959,-1 2.209,-1.375v1.25h2v-10.375z"
                          fill="#163e63"
                        ></path>
                        <path
                          d="M19.75,15c-1.654,0 -3,-1.374 -3,-3.063v-4.5c0,-1.688 1.346,-3.063 3,-3.063c1.654,0 3,1.374 3,3.063v4.5c0,1.689 -1.346,3.063 -3,3.063zM19.75,6.25c-0.552,0 -1,0.495 -1,1.104v4.667c0,0.609 0.448,1.104 1,1.104c0.552,0 1,-0.495 1,-1.104v-4.667c0,-0.609 -0.448,-1.104 -1,-1.104z"
                          fill="#163e63"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>

                <a href="/" target="_blank">
                  {/* svg Facebook */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="100"
                    viewBox="0,0,256,256"
                  >
                    <defs>
                      <linearGradient
                        x1="6.228"
                        y1="4.896"
                        x2="42.077"
                        y2="43.432"
                        gradientUnits="userSpaceOnUse"
                        id="color-1_yGcWL8copNNQ_gr1"
                      >
                        <stop offset="0" stopColor="#0d61a9"></stop>
                        <stop offset="1" stopColor="#16528c"></stop>
                      </linearGradient>
                    </defs>
                    <g transform="translate(-38.4,-38.4) scale(1.3,1.3)">
                      <g
                        fill="none"
                        fillRule="nonzero" // Thay đổi từ fill-rule
                        stroke="none"
                        strokeWidth="1" // Thay đổi từ stroke-width
                        strokeLinecap="butt" // Thay đổi từ stroke-linecap
                        strokeLinejoin="miter" // Thay đổi từ stroke-linejoin
                        strokeMiterlimit="10" // Thay đổi từ stroke-miterlimit
                        strokeDasharray=""
                        strokeDashoffset="0"
                        fontFamily="none" // Thay đổi từ font-family
                        fontWeight="none" // Thay đổi từ font-weight
                        fontSize="none" // Thay đổi từ font-size
                        textAnchor="none" // Thay đổi từ text-anchor
                      // style="mix-blend-mode: normal"
                      >
                        <g transform="scale(5.33333,5.33333)">
                          <path
                            d="M42,40c0,1.105 -0.895,2 -2,2h-32c-1.105,0 -2,-0.895 -2,-2v-32c0,-1.105 0.895,-2 2,-2h32c1.105,0 2,0.895 2,2z"
                            fill="url(#color-1_yGcWL8copNNQ_gr1)"
                          ></path>
                          <path
                            d="M25,38v-11h-4v-6h4v-2.138c0,-5.042 2.666,-7.818 7.505,-7.818c1.995,0 3.077,0.14 3.598,0.208l0.858,0.111l0.039,0.861v4.776h-3.635c-1.128,0 -1.365,1.378 -1.365,2.535v1.465h4.723l-0.928,6h-3.795v11z"
                            fill="#000000"
                            opacity="0.05"
                          ></path>
                          <path
                            d="M25.5,37.5v-11h-4v-5h4v-2.638c0,-4.788 2.422,-7.318 7.005,-7.318c1.971,0 3.03,0.138 3.54,0.204l0.436,0.057l0.02,0.442v4.253h-3.135c-1.623,0 -1.865,1.901 -1.865,3.035v1.965h4.64l-0.773,5h-3.868v11z"
                            fill="#000000"
                            opacity="0.07"
                          ></path>
                          <path
                            d="M33.365,16h2.635v-3.754c-0.492,-0.064 -1.531,-0.203 -3.495,-0.203c-4.101,0 -6.505,2.08 -6.505,6.819v3.138h-4v4h4v11h5v-11h3.938l0.618,-4h-4.556v-2.465c0,-1.874 0.612,-3.535 2.365,-3.535z"
                            fill="#ffffff"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
                <a href="/" target="_blank">
                  {/*svg zalo*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="100"
                    viewBox="0,0,256,256"
                  >
                    <g transform="translate(-62.72,-62.72) scale(1.49,1.49)">
                      <g
                        fill="none"
                        fillRule="nonzero" // Thay đổi từ fill-rule
                        stroke="none"
                        strokeWidth="1" // Thay đổi từ stroke-width
                        strokeLinecap="butt" // Thay đổi từ stroke-linecap
                        strokeLinejoin="miter" // Thay đổi từ stroke-linejoin
                        strokeMiterlimit="10" // Thay đổi từ stroke-miterlimit
                        strokeDasharray=""
                        strokeDashoffset="0"
                        fontFamily="none" // Thay đổi từ font-family
                        fontWeight="none" // Thay đổi từ font-weight
                        fontSize="none" // Thay đổi từ font-size
                        textAnchor="none" // Thay đổi từ text-anchor
                      // style="mix-blend-mode: normal"
                      >
                        <g transform="scale(2.56,2.56)">
                          <path
                            d="M69,82h-38c-7.18,0 -13,-5.82 -13,-13v-38c0,-7.18 5.82,-13 13,-13h38c7.18,0 13,5.82 13,13v38c0,7.18 -5.82,13 -13,13z"
                            fill="#1d588c"
                          ></path>
                          <path
                            d="M82,63.61v5.39c0,7.18 -5.82,13 -13,13h-32.7l-8.15,-8.15c-0.09,-0.09 -0.15,-0.21 -0.15,-0.35l0.92,0.26c0.59,0.3 1.92,0.74 4.58,0.74c3.54,0 6.25,-1.24 9.2,-2.12c1.08,-0.33 2.25,-0.3 3.31,0.09c3.57,1.31 7.44,2.03 11.49,2.03c9.84,0 18.63,-4.24 24.5,-10.89z"
                            fill="#073d71"
                          ></path>
                          <path
                            d="M82,31v33.24c-6.19,6.76 -15.08,10.64 -24.5,10.64c-4.01,0 -7.94,-0.7 -11.66,-2.07c-0.95,-0.35 -2.02,-0.37 -2.99,-0.08c-0.67,0.2 -1.31,0.42 -1.96,0.63c-2.24,0.74 -4.57,1.52 -7.39,1.52c-4.09,0 -5.24,-1.03 -5.35,-1.15c-0.12,-0.12 -0.17,-0.29 -0.14,-0.45c0.03,-0.16 0.14,-0.3 0.29,-0.36c0.04,-0.02 4.34,-1.92 6.02,-5.55c0.33,-0.73 0.17,-1.6 -0.41,-2.18c-5.75,-5.79 -8.91,-13.36 -8.91,-21.31c0,-10.52 5.43,-20.11 14.59,-25.88h29.41c7.18,0 13,5.82 13,13z"
                            fill="#fefdef"
                          ></path>
                          <path
                            d="M71.5,40c-3.309,0 -6,2.916 -6,6.5c0,3.584 2.691,6.5 6,6.5c3.309,0 6,-2.916 6,-6.5c0,-3.584 -2.691,-6.5 -6,-6.5zM71.5,49.5c-1.309,0 -2.375,-1.346 -2.375,-3c0,-1.654 1.066,-3 2.375,-3c1.309,0 2.375,1.346 2.375,3c0,1.654 -1.066,3 -2.375,3z"
                            fill="#1d588c"
                          ></path>
                          <path
                            d="M61.75,35.5c-0.966,0 -1.75,0.783 -1.75,1.75v14c0,0.967 0.784,1.75 1.75,1.75c0.966,0 1.75,-0.783 1.75,-1.75v-14c0,-0.967 -0.784,-1.75 -1.75,-1.75z"
                            fill="#1d588c"
                          ></path>
                          <path
                            d="M54.5,46.5c0,1.657 -1.119,3 -2.5,3c-1.381,0 -2.5,-1.343 -2.5,-3c0,-1.657 1.119,-3 2.5,-3c1.381,0 2.5,1.343 2.5,3zM56.25,40.25c-0.732,0 -1.357,0.45 -1.618,1.088c-0.905,-0.836 -2.031,-1.338 -3.257,-1.338c-2.969,0 -5.375,2.91 -5.375,6.5c0,3.59 2.406,6.5 5.375,6.5c1.226,0 2.352,-0.502 3.257,-1.338c0.261,0.638 0.887,1.088 1.618,1.088c0.966,0 1.75,-0.783 1.75,-1.75v-9c0,-0.967 -0.784,-1.75 -1.75,-1.75z"
                            fill="#1d588c"
                          ></path>
                          <path
                            d="M43.25,49h-5.667l6.732,-9.756c0.549,-0.796 0.349,-1.886 -0.447,-2.435c-0.302,-0.208 -0.646,-0.308 -0.987,-0.309c-0.002,0 -0.005,-0.001 -0.007,-0.001h-8.374c-0.897,0 -1.625,0.728 -1.625,1.625c0,0.897 0.728,1.625 1.625,1.625h5.214l-6.904,10.007c-0.369,0.535 -0.411,1.231 -0.109,1.808c0.302,0.577 0.899,0.936 1.549,0.936h9c0.966,0 1.75,-0.783 1.75,-1.75c0,-0.967 -0.784,-1.75 -1.75,-1.75z"
                            fill="#1d588c"
                          ></path>
                          <g fill="#1d588c">
                            <path d="M71.5,53.5c-3.584,0 -6.5,-3.141 -6.5,-7c0,-3.859 2.916,-7 6.5,-7c3.584,0 6.5,3.141 6.5,7c0,3.859 -2.916,7 -6.5,7zM71.5,40.5c-3.033,0 -5.5,2.691 -5.5,6c0,3.309 2.467,6 5.5,6c3.033,0 5.5,-2.691 5.5,-6c0,-3.309 -2.467,-6 -5.5,-6zM71.5,50c-1.585,0 -2.875,-1.57 -2.875,-3.5c0,-1.93 1.29,-3.5 2.875,-3.5c1.585,0 2.875,1.57 2.875,3.5c0,1.93 -1.29,3.5 -2.875,3.5zM71.5,44c-1.034,0 -1.875,1.121 -1.875,2.5c0,1.379 0.841,2.5 1.875,2.5c1.034,0 1.875,-1.121 1.875,-2.5c0,-1.379 -0.841,-2.5 -1.875,-2.5z"></path>
                            <path d="M61.75,53.5c-1.241,0 -2.25,-1.01 -2.25,-2.25v-14c0,-1.24 1.009,-2.25 2.25,-2.25c1.241,0 2.25,1.01 2.25,2.25v14c0,1.24 -1.009,2.25 -2.25,2.25zM61.75,36c-0.689,0 -1.25,0.561 -1.25,1.25v14c0,0.689 0.561,1.25 1.25,1.25c0.689,0 1.25,-0.561 1.25,-1.25v-14c0,-0.689 -0.561,-1.25 -1.25,-1.25z"></path>
                            <path d="M51.375,53.5c-3.239,0 -5.875,-3.141 -5.875,-7c0,-3.859 2.636,-7 5.875,-7c1.121,0 2.192,0.371 3.13,1.08c0.419,-0.518 1.054,-0.83 1.745,-0.83c1.241,0 2.25,1.01 2.25,2.25v9c0,1.24 -1.009,2.25 -2.25,2.25c-0.691,0 -1.325,-0.312 -1.745,-0.83c-0.938,0.709 -2.009,1.08 -3.13,1.08zM51.375,40.5c-2.688,0 -4.875,2.691 -4.875,6c0,3.309 2.187,6 4.875,6c1.056,0 2.064,-0.417 2.917,-1.205c0.12,-0.11 0.289,-0.156 0.447,-0.121c0.16,0.035 0.292,0.147 0.355,0.299c0.193,0.473 0.647,0.777 1.155,0.777c0.689,0 1.25,-0.561 1.25,-1.25v-9c0,-0.689 -0.561,-1.25 -1.25,-1.25c-0.508,0 -0.962,0.305 -1.155,0.777c-0.062,0.151 -0.195,0.264 -0.355,0.299c-0.158,0.034 -0.327,-0.01 -0.447,-0.121c-0.853,-0.788 -1.861,-1.205 -2.917,-1.205zM52,50c-1.654,0 -3,-1.57 -3,-3.5c0,-1.93 1.346,-3.5 3,-3.5c1.654,0 3,1.57 3,3.5c0,1.93 -1.346,3.5 -3,3.5zM52,44c-1.103,0 -2,1.121 -2,2.5c0,1.379 0.897,2.5 2,2.5c1.103,0 2,-1.121 2,-2.5c0,-1.379 -0.897,-2.5 -2,-2.5z"></path>
                            <path d="M43.25,53h-9c-0.839,0 -1.603,-0.462 -1.992,-1.204c-0.39,-0.743 -0.336,-1.634 0.14,-2.324l6.363,-9.222h-4.261c-1.172,0 -2.125,-0.953 -2.125,-2.125c0,-1.172 0.953,-2.125 2.125,-2.125h8.375c0.011,0 0.022,0 0.034,0.001c0.447,0.007 0.876,0.144 1.244,0.396c0.495,0.342 0.827,0.855 0.936,1.446c0.108,0.592 -0.02,1.189 -0.361,1.685l-6.192,8.972h4.714c1.241,0 2.25,1.01 2.25,2.25c0,1.24 -1.009,2.25 -2.25,2.25zM34.5,37c-0.62,0 -1.125,0.505 -1.125,1.125c0,0.62 0.505,1.125 1.125,1.125h5.214c0.186,0 0.356,0.104 0.443,0.268c0.086,0.165 0.074,0.363 -0.031,0.517l-6.905,10.005c-0.265,0.384 -0.294,0.878 -0.078,1.291c0.217,0.413 0.641,0.669 1.107,0.669h9c0.689,0 1.25,-0.561 1.25,-1.25c0,-0.689 -0.561,-1.25 -1.25,-1.25h-5.667c-0.186,0 -0.356,-0.104 -0.443,-0.268c-0.086,-0.165 -0.074,-0.363 0.031,-0.517l6.732,-9.756c0.392,-0.567 0.249,-1.348 -0.319,-1.739c-0.216,-0.147 -0.449,-0.192 -0.733,-0.221h-8.351z"></path>
                            <path d="M57.5,74.875c-4.013,0 -7.937,-0.693 -11.663,-2.061c-0.952,-0.351 -2.015,-0.379 -2.991,-0.081c-0.662,0.197 -1.309,0.412 -1.956,0.627c-2.245,0.744 -4.566,1.515 -7.391,1.515c-4.089,0 -5.236,-1.029 -5.354,-1.146c-0.118,-0.117 -0.168,-0.284 -0.138,-0.447c0.031,-0.162 0.141,-0.299 0.292,-0.365c0.043,-0.019 4.339,-1.916 6.015,-5.55c0.337,-0.722 0.172,-1.596 -0.41,-2.178c-5.741,-5.787 -8.904,-13.355 -8.904,-21.314c0,-10.864 5.801,-20.745 15.518,-26.432c0.236,-0.139 0.544,-0.06 0.684,0.179c0.139,0.238 0.059,0.545 -0.179,0.685c-9.407,5.505 -15.023,15.062 -15.023,25.568c0,7.693 3.06,15.012 8.615,20.607c0.877,0.877 1.122,2.205 0.608,3.305c-1.359,2.945 -4.17,4.796 -5.621,5.594c0.677,0.233 1.885,0.494 3.898,0.494c2.663,0 4.806,-0.711 7.076,-1.464c0.656,-0.218 1.313,-0.436 1.981,-0.636c1.18,-0.359 2.469,-0.325 3.625,0.1c3.615,1.327 7.423,2 11.318,2c2.655,0 5.307,-0.321 7.88,-0.955c0.269,-0.064 0.539,0.098 0.605,0.366c0.066,0.268 -0.098,0.539 -0.366,0.604c-2.651,0.654 -5.383,0.985 -8.119,0.985z"></path>
                            <path d="M74.5,70.208c-0.17,0 -0.335,-0.087 -0.429,-0.243c-0.142,-0.236 -0.065,-0.544 0.172,-0.686c0.332,-0.199 0.661,-0.404 0.984,-0.614c0.233,-0.15 0.542,-0.085 0.692,0.146c0.151,0.231 0.085,0.541 -0.146,0.691c-0.334,0.218 -0.673,0.429 -1.016,0.634c-0.081,0.05 -0.169,0.072 -0.257,0.072z"></path>
                            <path d="M69.5,72.638c-0.201,0 -0.39,-0.121 -0.466,-0.319c-0.1,-0.258 0.028,-0.548 0.286,-0.647c1.083,-0.418 2.049,-0.846 2.954,-1.305c0.244,-0.123 0.547,-0.027 0.672,0.22c0.125,0.246 0.027,0.548 -0.22,0.673c-0.935,0.474 -1.931,0.914 -3.046,1.346c-0.059,0.021 -0.12,0.032 -0.18,0.032z"></path>
                            <path d="M69,83h-38c-7.72,0 -14,-6.28 -14,-14v-38c0,-7.72 6.28,-14 14,-14h38c7.72,0 14,6.28 14,14v38c0,7.72 -6.28,14 -14,14zM31,19c-6.617,0 -12,5.383 -12,12v38c0,6.617 5.383,12 12,12h38c6.617,0 12,-5.383 12,-12v-38c0,-6.617 -5.383,-12 -12,-12z"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Cột 3 */}
        <div className="col-md-3">
          {/* Nội dung cột 3 sẽ được thêm sau */}
          <div className={styles["info-container"]}>
            <div className={styles["section-3"]}>
              <h1>GIỚI THIỆU</h1>
              <ul>
                <li><a href="/aboutus">Về chúng tôi</a></li>
                <li><a href="/">Hướng dẫn thanh toán</a></li>
                <li><a href="/">Hướng dẫn đặt tour</a></li>
                <li><a href="/">Bảng Giá</a></li>
                <li><a href="/">Tour Khuyển Mãi</a></li>
              </ul>
            </div>
          </div>
        </div>

        {/* Cột 4 */}
        <div className="col-md-3">
          {/* Nội dung cột 4 sẽ được thêm sau */}
          <div className={styles["info-container"]}>
            <div className={styles["section-4"]}>
              <h1>CHÍNH SÁCH</h1>
              <ul>
                <li><a href="/">Chính sách bảo mật</a></li>
                <li><a href="/">Điều khoản chung</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};

export default Info;
